@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: #1a1a1a url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23333333' fill-opacity='0.4' d='M2 6h2v2H2V6zm4-4h2v2H6V2z'%3E%3C/path%3E%3C/svg%3E");
  color: #e0e0e0;
  min-height: 100vh;
  line-height: 1.6;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.header {
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  color: #FF3C00;
  font-size: 2.8em;
  margin: 0;
  font-weight: 600;
  text-shadow: 0 0 15px rgba(255, 60, 0, 0.5);
  letter-spacing: -0.5px;
}

.header nav {
  display: flex;
  gap: 30px;
}

.header nav a {
  color: #e0e0e0;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.1em;
  transition: all 0.3s ease;
  position: relative;
}

.header nav a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #FF3C00;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.header nav a:hover::after {
  transform: scaleX(1);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.tools-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1000px;
  margin: 50px auto;
}

.tool-card {
  background: rgba(42, 42, 42, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 250px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.tool-card:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 12px 40px rgba(255, 60, 0, 0.2);
  border-color: rgba(255, 60, 0, 0.3);
}

.tool-card h2 {
  color: #FF3C00;
  margin-top: 0;
  font-size: 1.8em;
  font-weight: 600;
  border-bottom: 2px solid rgba(255, 60, 0, 0.3);
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.tool-card p {
  margin: 15px 0;
  line-height: 1.8;
  font-size: 1.1em;
}

.tool-link {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  background: linear-gradient(135deg, #FF3C00, #FF7E00);
  color: #ffffff;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  font-size: 1.1em;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 60, 0, 0.3);
}

.tool-link:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(255, 60, 0, 0.4);
}

/* Common styles */
.card {
  background: rgba(42, 42, 42, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  margin-top: 20px;
  padding: 30px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(255, 60, 0, 0.2);
  border-color: rgba(255, 60, 0, 0.3);
}

h2 {
  color: #FF3C00;
  margin-top: 0;
  font-size: 2em;
  font-weight: 600;
  border-bottom: 2px solid rgba(255, 60, 0, 0.3);
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.error-alert {
  background-color: rgba(239, 68, 68, 0.2);
  color: #f87171;
  padding: 20px;
  margin-bottom: 25px;
  border-radius: 10px;
  border: 1px solid rgba(239, 68, 68, 0.5);
  font-size: 1.1em;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
  color: #FF3C00;
}

/* Styles for links */
a {
  color: #FF3C00;
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  color: #ff5c33;
  text-decoration: underline;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.loading-indicator {
  animation: pulse 1.5s infinite ease-in-out;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .tools-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 20px;
  }

  .header h1 {
    font-size: 2.4em;
  }

  .tools-grid {
    grid-template-columns: 1fr;
  }

  .tool-card {
    height: auto;
    min-height: 200px;
  }
}

@media (max-width: 480px) {
  .App {
    padding: 20px 10px;
  }

  .header h1 {
    font-size: 2em;
  }

  .header nav {
    gap: 15px;
  }

  .header nav a {
    font-size: 1em;
  }

  .tool-card h2 {
    font-size: 1.5em;
  }

  .tool-card p {
    font-size: 1em;
  }

  .tool-link {
    font-size: 1em;
    padding: 10px 20px;
  }
}

.wallet-adapter-button {
  background-color: #FF3C00 !important;
  color: white !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  border-radius: 15px;
}

.wallet-adapter-button:hover {
  background-color: #ff5c33 !important;
}

.wallet-not-connected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  text-align: center;
}

.wallet-not-connected h2 {
  color: #FF3C00;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.header nav {
  margin-right: 20px;
}

.wallet-not-connected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  text-align: center;
}

.wallet-not-connected h2 {
  color: #FF3C00;
  margin-bottom: 20px;
}


.sol-balance {
  background-color: rgba(255, 60, 0, 0.1);
  color: #FF3C00;
  padding: 8px 12px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 15px;
  }

  .wallet-info {
    flex-direction: column;
  }
}

.paywall-message {
  background-color: rgba(255, 60, 0, 0.1);
  border: 1px solid #FF3C00;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}

.paywall-message h2 {
  color: #FF3C00;
}

.paywall-toggle {
  background-color: #FF3C00;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.paywall-toggle:hover {
  background-color: #ff5c33;
}

.token-gate-message {
  background-color: rgba(255, 60, 0, 0.1);
  border: 1px solid #FF3C00;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}

.token-gate-message h2 {
  color: #FF3C00;
}

.wallet-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.subscription-status {
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 0.9em;
  font-weight: 600;
}

.subscription-status.subscribed {
  background-color: rgba(0, 255, 0, 0.2);
  color: #00c853;
}

.subscription-status.not-subscribed {
  background-color: rgba(255, 60, 0, 0.1);
  color: #FF3C00;
}

.subscription-status.checking {
  background-color: rgba(255, 193, 7, 0.2);
  color: #ffc107;
}


.subscribe-button, .delete-subscription-button {
  background: linear-gradient(135deg, #FF3C00, #FF7E00);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 60, 0, 0.3);
  margin-top: 15px;
}

.subscribe-button:hover, .delete-subscription-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(255, 60, 0, 0.4);
}

.delete-subscription-button {
  background: linear-gradient(135deg, #FF3C00, #FF0000);
  margin-top: 20px;
}

.subscription-message {
  background-color: rgba(255, 60, 0, 0.1);
  border: 1px solid #FF3C00;
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  margin-top: 20px;
  box-shadow: 0 8px 32px rgba(255, 60, 0, 0.1);
}

.subscription-message h2 {
  color: #FF3C00;
  margin-bottom: 15px;
}

.subscription-message p {
  margin-bottom: 20px;
}

.otc-market-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.offers-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.offer-card {
  background-color: rgba(42, 42, 42, 0.7);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.offer-card h2 {
  color: #FF3C00;
  margin-top: 0;
  border-bottom: 1px solid #FF3C00;
  padding-bottom: 10px;
}

.offer-details {
  flex-grow: 1;
}

.creator {
  margin-top: auto;
  font-size: 0.9em;
  color: #888;
}

.accept-offer-btn, .create-offer-btn {
  background-color: #FF3C00;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

.create-offer-section {
  background-color: rgba(42, 42, 42, 0.7);
  border-radius: 10px;
  padding: 20px;
}

.create-offer-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #333;
  color: white;
}

.create-offer-btn {
  grid-column: span 2;
  justify-self: center;
}

@media (max-width: 768px) {
  .offers-grid {
    grid-template-columns: 1fr;
  }

  .create-offer-form {
    grid-template-columns: 1fr;
  }

  .create-offer-btn {
    grid-column: 1;
  }
}