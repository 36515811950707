.dca-dashboard {
    padding: 40px;
    background: rgba(42, 42, 42, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }
  
  .token-input-form {
    display: flex;
    justify-content: center;
    margin: 40px 0;
  }
  
  .token-input {
    width: 400px;
    padding: 15px 20px;
    font-size: 1.1em;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px 0 0 30px;
    background-color: rgba(42, 42, 42, 0.7);
    color: #e0e0e0;
    transition: all 0.3s ease;
  }
  
  .token-input:focus {
    outline: none;
    border-color: #FF3C00;
    box-shadow: 0 0 0 3px rgba(255, 60, 0, 0.3);
  }
  
  .token-input::placeholder {
    color: #888;
  }
  
  .fetch-button {
    padding: 15px 30px;
    font-size: 1.1em;
    font-weight: 600;
    color: #ffffff;
    background: linear-gradient(135deg, #FF3C00, #FF7E00);
    border: none;
    border-radius: 0 30px 30px 0;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(255, 60, 0, 0.3);
  }
  
  .fetch-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(255, 60, 0, 0.4);
  }
  
  .fetch-button:active {
    transform: translateY(1px);
  }
  
  .token-info {
    background: rgba(42, 42, 42, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 30px;
    margin-top: 40px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.4s ease;
  }
  
  .token-info:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(255, 60, 0, 0.2);
    border-color: rgba(255, 60, 0, 0.3);
  }
  
  .token-info h2 {
    color: #FF3C00;
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 1.8em;
    font-weight: 600;
    border-bottom: 2px solid rgba(255, 60, 0, 0.3);
    padding-bottom: 15px;
  }
  
  .token-info p {
    margin: 15px 0;
    font-size: 1.1em;
    line-height: 1.8;
  }
  
  .token-info strong {
    color: #FF3C00;
    font-weight: 600;
    margin-right: 10px;
  }
  
  .dca-accounts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .active-accounts-count {
    color: #FF3C00;
    font-weight: 600;
    font-size: 1.2em;
  }
  
  .dca-accounts-container {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
  
  .account-card {
    background: rgba(42, 42, 42, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.4s ease;
  }
  
  .account-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 40px rgba(255, 60, 0, 0.2);
    border-color: rgba(255, 60, 0, 0.3);
  }
  
  .account-card h2 {
    margin-top: 0;
    margin-bottom: 25px;
    color: #FF3C00;
    font-size: 1.5em;
    font-weight: 600;
  }
  
  .account-details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 15px;
  }
  
  .detail-row:last-child {
    border-bottom: none;
  }
  
  .detail-label {
    font-weight: 600;
    color: #888;
  }
  
  .detail-value {
    color: #e0e0e0;
    text-align: right;
  }
  
  .price-chart-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    background: rgba(42, 42, 42, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 30px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.4s ease;
  }
  
  .price-chart-placeholder:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(255, 60, 0, 0.2);
    border-color: rgba(255, 60, 0, 0.3);
  }
  
  .price-chart-placeholder h2 {
    color: #FF3C00;
    margin-bottom: 20px;
  }
  
  .price-chart-placeholder p {
    color: #e0e0e0;
    font-size: 1.1em;
    line-height: 1.8;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .dca-dashboard {
      padding: 20px;
    }
  
    .token-input-form {
      flex-direction: column;
      align-items: center;
    }
  
    .token-input {
      width: 100%;
      max-width: 400px;
      border-radius: 30px;
      margin-bottom: 10px;
    }
  
    .fetch-button {
      width: 100%;
      max-width: 400px;
      border-radius: 30px;
    }
  
    .dca-accounts-container {
      grid-template-columns: 1fr;
    }
  
    .dca-accounts-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .token-info h2,
    .account-card h2 {
      font-size: 1.3em;
    }
  
    .token-info p,
    .detail-label,
    .detail-value {
      font-size: 0.9em;
    }
  }